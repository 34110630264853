import { cn } from '@my/magic-ui'
import { motion } from 'framer-motion'
import { ArrowUpRight } from 'lucide-react'
import { useState } from 'react'

interface ButtonProps {
  children: React.ReactNode
  onClick?: () => void
  className?: string
  showArrow?: boolean
  mode?: 'primary' | 'white' | 'transparent'
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const Button = ({
  children,
  onClick,
  className,
  showArrow = true,
  mode = 'primary',
  disabled = false,
  loading = false,
  type = 'button',
}: ButtonProps) => {
  const [hovered, setHovered] = useState(false)

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={cn(
        'flex w-full cursor-pointer items-center justify-center gap-2 rounded-full py-[20px] text-lg font-medium text-white transition-all duration-300',
        mode === 'primary'
          ? 'bg-gradient-to-r from-[#4A3AFF] to-[#0D009B]'
          : mode === 'white'
            ? 'bg-white text-[#0D009B]'
            : 'rounded-full border border-solid border-white/20 bg-transparent',
        className,
        disabled || loading ? 'opacity-50' : ''
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      {loading ? (
        <motion.div
          className="h-6 w-6 rounded-full border-t-2 border-white"
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
        />
      ) : (
        <>
          {children}
          {showArrow && (
            <motion.div
              animate={hovered ? { rotate: 45, scale: 1.1 } : { rotate: 0, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <ArrowUpRight className="h-5 w-5" />
            </motion.div>
          )}
        </>
      )}
    </button>
  )
}
